import { ISPLLocale, ISPLNavigation } from './ispl-locale';

export const navExtras: ISPLNavigation = {
  EXTRA_NAV: {
    REGISTER: 'Register',
    THREATMENT: 'Training',
    ABOUT: 'About Sports performance Lab',
    SIGN_OUT: 'Sign out',
    EDIT_PROFILE: 'Edit Profile',
    TRAINING_OVERVIEW: 'Training Overview',
  },
};

export const locale: ISPLLocale = {
  lang: 'us',
  code: 'US',
  humanTitle: 'English',
  data: {
    COMMON: {
      EMAIL: 'Email',
      YOUR_EMAIL: 'Your Email',
      EMAIL_INVALID: 'Email is invalid',
      EMAIL_REQUIRED: 'Email is required',
      PHONE: 'Phone',
      YOUR_PHONE: 'Your Phone',
      PHONE_INVALID: 'Phone Invalid',
      PHONE_REQUIRED: 'Phone Required',
      UNIQUE_ID: 'Unique Id',
      UNIQUE_ID_INVALID: 'Invalid Unique Id',
      UNIQUE_ID_REQUIRED: 'Unique Id is required',
      PASSWORD: 'Password',
      YOUR_PASSWORD: 'Your Password',
      PASSWORD_REQUIRED: 'Password required',
      PASSWORD_MUST_MATCH: 'Passwords must match',
      HERE: 'here',
      YOUR_LANGUAGE: 'Your Language',
      PERSONAL_INFO: {
        NAME: 'Name',
        NAME_REQUIRED: 'Name is required',
        NAME_INVALID: 'Please enter a valid Name',
        YOUR_PERSONAL_INFO: 'Your Personal Info',
        YOUR_DATE_OF_BIRTH: 'Your Date Of Birth',
        DATE_OF_BIRTH_REQUIRED: 'Date Of Birth is required',
        DATE_OF_BIRTH_INVALID: 'Please enter a valid Date Of Birth',
        DATE_OF_BIRTH_MINIMAL: 'Minimal age is 18 years',
        YOUR_FIRST_NAME: 'Your First Name',
        FIRST_NAME_REQUIRED: 'First Name is required',
        FIRST_NAME_INVALID: 'Please enter a valid First Name',
        YOUR_LAST_NAME: 'Your Last Name',
        LAST_NAME_REQUIRED: 'Last Name is required',
        LAST_NAME_INVALID: 'Please enter a valid Last Name',
        ZIP_CODE: 'Zip Code',
        ZIP_CODE_REQUIRED: 'Zip Code is required',
      },
      ACTIONS: {
        CONTINUE: 'Continue',
        GO_BACK_A_STEP: 'Go Back A Step',
        GO_BACK: 'Go Back',
        BACK: 'Back',
        GO_BACK_TO_LOGIN: 'Go back to login',
        GO_TO_SIGNIN: 'Go To Sign-In',
        SAVE: 'Save',
        CANCEL: 'Cancel',
        ACKNOWLEDGE: 'Acknowledge',
        SIGN_OUT: 'Sign Out',
        REMOVE: 'Remove',
        START: 'Start',
        STOP: 'Stop',
        SKIP: 'Skip',
        EDIT: 'Edit',
        YES: 'YES',
        NO: 'NO',
      },
      STATUSES: {
        SUCCSESS: 'Success!',
        FAILURE: 'Failure!',
      },
      TIME: {
        WEEK: 'Week',
        DAY: 'Day',
        DATE: 'Date',
        TODAY: 'Today',
        TOMORROW: 'Tomorrow',
        MON_TEXT: 'Monday',
        TUE_TEXT: 'Tuesday',
        WEN_TEXT: 'Wednesday',
        THU_TEXT: 'Thursday',
        FRI_TEXT: 'Friday',
        SAT_TEXT: 'Satruday',
        SAN_TEXT: 'Sunday',
      },
      DOMAIN_COMMON: {
        IN_STUDIO: 'In-Clinic',
        OUT_OF_STUDIO: 'At Home',
        SCHEDULE: 'Schedule',
        OVERVIEW: 'Overview',
        CLASS: 'Class',
        LOCATION: 'Location',
        STATE: 'State',
        TRAINER: 'Trainer',
        STATION: 'Station',
      },
    },
    DIALOGS: {
      WARNING_TITLE: 'Warning',
      DELETE_ACC_TITLE1: 'Account',
      DELETE_ACC_TITLE2: 'will be deleted',
      DELETE_ACC_DESC: 'Do you want to continue?',
      ATTEMPT_START_NEW_LVL:
        'Wait. You are attempting to start a new level of your program without completing the previous level. We recommend you go back and complete training day: {replace1} prior to completing training day: (Week {replace2} Day {replace3})',
      ATTEMPT_START_NO_REST:
        'Wait. You are attempting to complete another training day without adequate rest and recovery. We recommend 24-48 hours in between training days to optimize system healing and regeneration.',
      INSTRUCTOR_CHAT:
        'If you have any questions about your exercise program, you can contact {replace1} using text message. Initiate SMS conversation now?',
    },
    NAV: {
      ROOT: 'Root',
      DASHBOARD: 'Dashboard',
      TRAINING: 'Training',
      TRAINING_SESSION: 'Training Session',
      TRAINING_OVERVIEW: 'Training Overview',
      RESCHEDULE_IN_STUDIO_SESSION: 'Reschedule In Studio Session',
      RESCHEDULE_SESSION: 'Reschedule Session',
      TRAINING_DETAILS: 'Training Details',
      GREAT_WORKOUT: 'Great Workout',
      RESCHEDULE_IN_STUDIO_TRAINING: 'Reschedule In Studio Training',
      SCHEDULE_IN_STUDIO_TRAINING: 'Schedule In-Studio Training',
      SCHEDULE_ASSESSMENT: 'Schedule Assessment',
      TESTING: 'Testing',
      TESTING_HISTORY: 'E - Testing History',
      TESTING_ASSESSMENT_HISTORY: 'Testing Assessment History',
      TEST_SUMMARY: 'Test Summary',
      NOT_AUTHORIZED: 'Not Authorized',
      PREFERENCES: 'Preferences',
      EDIT_PROFILE: 'Edit Profile',
      CHANGE_PASSWORD: 'Change Password',
      SWITCH_PROFILE: 'Switch Profile',
      SIGNED_IN_AS: 'Signed In As',
      TESTING_OVERVIEW: 'Testing Overview',
    },
    EXTRA_NAV: navExtras.EXTRA_NAV,
    LOGIN: {
      SIGN_IN: 'Sign In',
      SIGNING_IN: 'Signing In...',
      PASSWORD: 'Password',
      PASSWORD_REQUIRED: 'Password required',
      NEW_USER: 'New user?',
      REGISTER: 'Register',
      FORGOT_PASSWORD: ' Forgot Password?',
      RETURN_URL_ERROR:
        'Invalid return url. The return url needs to have the same origin as the current page.',
    },
    REGISTER: {
      CREATE_AN_ACCOUNT: 'Create An Account',
      ENTER_YOUR_CONACT_INFO:
        'Enter your contact info which will be used for signing in.',
      FOR_ADULTS: 'If you are an adult, please use your own information.',
      AGE_WARNING: 'You need to be 18-years or older to create an account.',
      ENTER_UNIQUE_ID:
        'Enter Unique Id and Date of Birth to identify if you are eligible to register',
      UNABLE_TO_VERIFY_EMAIL_UNIQ_ID:
        'We were unable to verify your email address. Please provide your unique identifier from registration invitation email and date of birth to create account',
      CREATE_WITH_UNIQ_ID: 'Create with unique id',
      RETURN_TO_SIGN_IN: 'Return To Sign-in',
      CREATE_PROFILE: 'Create Profile',
      DATE: 'Date',
      DATE_REQUIRED: 'Date is required',
      DATE_INVALID: 'Please enter a valid Date',
      WAIVER_OF_LIABILITY: 'Waiver Of Liability',
      I_AGREE: 'I Agree',
    },
    REGISTER_COMPLETE: {
      REGISTRATION_FINISHED: 'Registration Finished!',
      YOU_REGISTERED:
        "Now that you've registered, you need to create a profile for each person using this app.", // eslint-disable-line @typescript-eslint/quotes
    },
    IN_TAKE: {
      PROFILE_CREATED: 'Profile Created!',
      YOU_CREATED_PROFILE: 'You’ve successfully created a profile.',
      GOTO_DASHBOARD: 'Go To Dashboard',
      ADD_ANOTHER_PROFILE: 'Add Another Profile',
    },
    PERSONAL_INFO: {
      ENTER_YOUR_PERSONAL_INFO: 'Enter Your Personal Info',
      IF_YOU_CREATING_FOR_YOUTH:
        'If you’re setting this app up for a youth you’ll create a profile for them later in the process.',
      DATE_OF_BIRTH_TO_SMALL_NOFITICATION:
        'You need to be 18-years or older to create an account. If you’re a youth, ask an adult to register themselves then create a profile for you.',
    },
    FORGOT_PASSWORD: {
      TO_RESET_PASSWORD:
        "To reset your password, enter your email or phone and we'll send you a link to change your password.", // eslint-disable-line @typescript-eslint/quotes
      EMAIL_OR_PHONE: 'Email Or Phone',
      ENTER_VALID_EMAIL: 'Please enter a valid email address',
      REQUEST_NEW_PASSWORD: 'Request New Password',
      CHECK_YOUR_EMAIL_ADDRESS: 'Check your email address!',
      CONFIRMATION_SENT_TO: 'A confirmation e-mail has been sent to',
      CHECK_INBOX:
        'Check your inbox and click on the "Change password" link to reset your password.',
    },
    RESET_PASSWORD: {
      PLEASE_CHANGE_YOUR_PASSWORD:
        'Please change your password to a secure and memorable password.',
      PASSWORD_MUST_INCLUDE:
        'It must include 8 characters, 1 special character, and 1 number.',
      UPDATE_PASSWORD: 'Update Password',
      YOU_UPDATED_PASSWORD: 'You’ve updated your password and can now sign in.',
      RESET_PASSWORD_REQUEST_INCORRECT:
        "Reset password request URL you are trying to use has already been used and can't be used again.", // eslint-disable-line @typescript-eslint/quotes
      CONFIRM_PASSWORD: 'Confirm Password',
    },
    CHANGE_PASSWORD: {
      CHANGE_PASSWORD: 'Change Password',
      CREATE_SECURE_PASSWORD:
        'Create a secure and yet memorable password for your account.',
      PASSWORD_MUST_INCLUDE:
        'It must include 8 characters, 1 special character, and 1 number.',
      PASSWORD_MUST_MATCH: 'Passwords must match',
      CONFIRM_NEW_PASSWORD: 'Confirm New Password',
      CONFIRM_NEW_PASSWORD_REQUIRED: 'Confirm New Password is required',
      NEW_PASSWORD: 'New Password',
      NEW_PASSWORD_REQUIRED: 'New Password is required',
      PASSWORD_POLICY_ERROR: 'Password policy error',
      PASSWORD_WAS_CHANGED: 'Password was changed',
    },
    CHANGE_PROFILE: {
      CHANGE_PROFILE: 'Select Profile',
      WHICH_PROFILE: 'Which profile do you want to use?',
      CONTINUE_TO_DASHBOARD: 'Continue To Dashboard',
      ADD_ANOTHER_PROFILE: 'Add Another Profile',
      DELETE_ACCOUNT: 'Delete Account',
    },
    CHECK_UNIQ_ID: {
      ADD_PROFILE: 'Add Profile',
      YOU_CAN_LINK_EXISTING:
        'You can link an existing record by using your unique ID',
      OR_CREATE_NEW: 'Or Create New',
      LINK_EXISTING: 'Link Existing',
    },
    DASHBOARD: {
      TREATMENT: 'Treatment',
      UPCOMING_EVENTS: 'Upcoming Events',
      UPCOMING_EVENTS_LIST: 'Here’s a list of your upcoming events.',
      NO_UPCOMING_EVENTS: 'No upcoming events',
      YOU_HAVE_PROGRAMM: 'You have exercise program assigned.',
      TO_SEE_EXERCISES_TAP: 'To see exercises tap',
      CHANGE_SCHEDULE: 'Change Schedule',
      TESTING: 'Testing',
      CONTACT: 'Contact',
      BOOK_NOW: 'Book Now',
      CALL: 'Call',
      CHAT: 'Chat',
      OPT_MOBILE_ONLY: 'This option is available only on a mobile.',
      PHONE_NOT_CONFIGURED:
        'Phone number is not configured for a given provider.',
    },
    ASSESSMENTS: {
      UPCOMING_TESTS: 'Upcoming Tests',
      NO_UPCOMING_TESTS: 'No upcoming tests',
      DIAGNOSIS: 'Diagnosis',
      EXERCISE_PROGRAM: 'Exercise Program',
      EXERCISE_PROGRAM_ASSIGNED:
        'Here is the exercise program assigned to you after completing the test.',
      NO_COMPLETED_TESTS:
        'This assessment does not contain any completed test.',
      NO_DIAGNOSIS: 'There is no diagnosis yet.',
      NO_EXERCISE_PROGRAM: 'There is no exercise program assigned yet.',
      TAP_TO_VIEW_RESULTS:
        ' Tap one of the items below to view the results from each testing category.',

      PDF_REPORT: 'PDF Report',
      RESULTS_NOT_AVAILIABLE:
        'Your test results will be available for download soon.',
      TAP_TO_DOWNLOAD_REPORT: 'Tap on the icon to download your test results.',

      TEST_SUMMARY: 'Test Summary',
      VISION_TEST_TITLE: 'Vision',
      VISION_TEST_DESC:
        'The Sensory Motor Skills Assessment analyzes the connection between your eyes, brain, and body. The data collected during this assessment is compared to a vast database that matches age, gender, sport, and position for athletes, into a percentile of comparison. The higher the number for said percentile, the better performance observed during the assessment.',
    },
    TRAININGS: {
      EXERCISE_PROGRAM: 'Exercise Program',
      COMPLETENESS: 'Completeness',
      NO_PROGRAMM_ASSIGNED: 'There is no exercise program assigned to you',
      NO_PROGRAMM_ASSIGNED_YET: 'There is no exercise program assigned yet.',
      HISTORY: 'History',
      HERE_IS_HISTORY: 'Here is a history of your past',
      TAP_ONE_TO_VIEW: 'Tap one to view your results from that day',
      DATE: 'Date',
      YOU_ARE_AT: 'You Are At',
      NO_EXERCISE_FOR_STATION: 'There is no exercise for this station.',
      SAVE_YOUR_STATS: 'Save Your Stats',
      SAVING_YOUR_STATS: 'Saving Your Stats',
      STATS_SAVED: 'Stats Saved',
      TRAINING_DETAILS: 'Treatment Details',
      TRAINING_AVAIL_24:
        'Treatment is available for data entering just next 24 hours after it is ended if it was not confirmed.',
      CUSTOMIZED_PROGRAM_FOR: 'Customized Program For',
      EXERCISES: 'Exercises',
      VIDEO_NOT_FOUND: 'Video not available',
      ARE_YOU_IN_SEASON: 'Are you currently in-season?',
      THERE_IS_NO_EX_FOR_STATION: 'There is no exercise for this station.',
      YOUR_ARE_AT: 'You are at',
    },
    ABOUT: {
      CLIENT_VERSION: 'Client Version',
      API_VERSION: 'API Version',
    },
    PREFERENCES: {
      MEDICAL_COVERAGE: 'Medical Coverage',
      CARRIER: 'Carrier',
      CARRIER_REQUIRED: 'Carrier is required',
      GROUP_NUMBER: 'Group number',
      GROUP_NUMBER_REQUIRED: 'Group number is required',
      MEMBER_ID: 'Member ID',
      MEMBER_ID_REQUIRED: 'Member ID is required',
      INSURED_NAME: 'Insured name',
      INSURED_NAME_REQUIRED: 'Insured name is required',
      INSURED_DATE_OF_BIRTH: 'Insured date of birth',
      INSURED_DATE_OF_BIRTH_REQUIRED: 'Insured date of birth is required',
      INSURANCE_CARD: 'Insurance card',
      ADD_INSURANCE_CARD: 'Add Insurance card',
      REMOVE_INSURANCE_CARD:
        'Are you sure you want to remove the insurance card?',
    },
    RECOMMENDATIONS: {
      RECOMMENDATIONS: 'Recommendations',
      NO_RECOMMENDATIONS: 'There are no recommendations yet',
      BOOK_NOW: 'Book Now Using One of these Options:',
      GET_MEDICAL_TREATMENT1: 'To get medical treatment you should add your',
      GET_MEDICAL_TREATMENT2: 'insurance information',
      GET_MEDICAL_TREATMENT3: 'first',
    },
    TESTING_HISTORY: {
      TESTING_HISTORY: 'Testing History',
      HERE_IS_YOUR_HISTORY:
        'Here is a history of your past testing. Tap one to view your results from that test.',
    },
    EVENTS_INFO: {
      TOTAL_TESTS: 'Total Tests<br/>You’ve Taken',
      LAST_TEST_DATE: 'Last Test<br/>Date',
      SESSIONS: 'Sessions',
      UNTIL_NEXT_TEST: 'Until Next Test',
    },
  },
};
